.editor-main {
    .editor-wrapper {
      border: 1px solid black;
      border-radius: 5px;
    }
    .editor-toolbar {
      border-bottom: 1px solid rgba(204, 204, 204, 0.582);
    }
    .editor {
      margin: 0px 8px;
      height: 200px;
    }
    .public-DraftStyleDefault-block {
      margin: 5px 0 !important;
    }
  }
  